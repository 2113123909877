step {
    position: relative;
    min-height: 1em;
    color: grey;
}

.step+.step {
    margin-top: 1.5em
}

.step>div:first-child {
    position: static;
    height: 0;
}

.step>div:not(:first-child) {
    margin-left: 1.5em;
    padding-left: 1em;
}

.step.step-active {
    color: #4285f4
}

.step.step-active .circle {
    background-color: #0069B4;
}

/* Circle */
.circle {
    background: #dddddd;
    position: relative;
    width: 1.5em;
    height: 1.5em;
    line-height: 1.5em;
    border-radius: 100%;
    color: #fff;
    text-align: center;
    box-shadow: 0 0 0 3px #fff;
}

/* Vertical Line */
.circle:after {
    content: ' ';
    position: absolute;
    display: block;
    top: 1px;
    right: 50%;
    bottom: 1px;
    left: 50%;
    height: 100%;
    width: 2px;
    transform: scale(1, 2);
    transform-origin: 50% -100%;
    background-color: #dddddd;
}


.circle1 {
    background: #0069B4;
    position: relative;
    width: 1.5em;
    height: 1.5em;
    line-height: 1.5em;
    border-radius: 100%;
    color: #fff;
    text-align: center;
    box-shadow: 0 0 0 3px #fff;
}

/* Vertical Line */
.circle1:after {
    content: ' ';
    position: absolute;
    display: block;
    top: 1px;
    right: 50%;
    bottom: 1px;
    left: 50%;
    height: 100%;
    width: 2px;
    transform: scale(1, 2);
    transform-origin: 50% -100%;
    background-color: #0069B4;
}
.step:last-child .circle:after {
    display: none;
    z-index: -1;
}
.step:last-child .circle1:after {
    display: none;
    z-index: -1;
}
.step:first-child .circle:after {
    height: 100%;
}
.step:first-child .circle1:after {
    height: 100%;
}
/* Stepper Titles */
.title {
    line-height: 1.5em;
    font-weight: bold;
}

.caption {
    font-size: 0.8em;
}

/* Red Circle */
.circle-red {
    background: red;
    position: relative;
    width: 1.5em;
    height: 1.5em;
    line-height: 1.5em;
    border-radius: 100%;
    color: #fff;
    text-align: center;
    box-shadow: 0 0 0 3px #fff;
}

/* Vertical Line */
.circle-red:after {
    content: ' ';
    position: absolute;
    display: block;
    top: 1px;
    right: 50%;
    bottom: 1px;
    left: 50%;
    height: 100%;
    width: 2px;
    transform: scale(1, 2);
    transform-origin: 50% -100%;
    background-color: red;
}