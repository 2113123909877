// variables

// font-size
.fs-5{
    font-size: 1.5rem !important;
}

// font-weight
.fw-200{
    font-weight: 200!important;
}
.fw-300{
    font-weight: 300!important;
}
.fw-400{
    font-weight: 400!important;
}
.fw-500{
    font-weight: 500!important;
}
.fw-600{
    font-weight: 600!important;
}
.fw-700{
    font-weight: 700!important;
}
.fw-800{
    font-weight: 800!important;
}
.fw-900{
    font-weight: 900!important;
}
.fw-bold{
    font-weight: 500 !important;
}

//bg color 
.bg-indgo{
    background-color:#153c86 !important;   
}

//text color
.text-indgo{
    color:#153c86 !important;
}

// button 
.btn-primary {
    box-shadow: none !important;
    border-color: #153c86 !important;
    background-color: #153c86 !important ;
    color: white !important;
}
.bg-app{
    background-color: #153c86 !important;
}